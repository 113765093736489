import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { DxModule } from '../../../../goldstar-share/src/app/modules/dx/dx.module';
import { MaterialModule } from '../../../../goldstar-share/src/app/modules/material/material.module';
import { CountryPhoneNumberComponent } from './components/country-phone-number/country-phone-number.component';
import { StaffScheduleDetailsComponent } from '../modules/schedule-appointment/components/meeting-details/staff-schedule-details/staff-schedule-details.component';
import { DxCalendarModule } from 'devextreme-angular';
import { PhoneNumberMaskPipe } from '../../../../goldstar-share/src/app/components/shared/phone-number-mask/phone-number-mask.pipe';
//import { OtpInputComponent } from '../modules/schedule-appointment/shared/components/otp-input/otp-input.component';
import { OtpInputComponent } from './components/otp/components/otp-input/otp-input.component';
import { OtpDialogComponent } from './components/otp/components/otp-dialog/otp-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagePreferenceSelectionComponent } from './components/language-preference-selection/language-preference-selection.component';

@NgModule({
	declarations: [LoaderComponent, CountryPhoneNumberComponent, StaffScheduleDetailsComponent, PhoneNumberMaskPipe, OtpInputComponent, LanguagePreferenceSelectionComponent, OtpDialogComponent],
	imports: [DatePipe, CommonModule, DxModule, MaterialModule, RouterModule, NgxSpinnerModule, FormsModule, ReactiveFormsModule, DxCalendarModule, TranslateModule],
	exports: [LoaderComponent, CountryPhoneNumberComponent, StaffScheduleDetailsComponent, PhoneNumberMaskPipe, OtpInputComponent, LanguagePreferenceSelectionComponent, OtpDialogComponent],
})
export class SharedComponentModule {}
